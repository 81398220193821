import React from "react";
import { Helmet } from "react-helmet";
import "./TermsAndConditions.scss";

const TermsAndConditions = () => (
  <React.Fragment>
    <Helmet>
      <title>Terms & Conditions | Decpay</title>
      <link rel="canonical" href="https://decpay.in/terms/" />
      <meta name="description" content="Terms & Conditions | Decpay" />
      <meta name="keywords" content="terms, conditions, decpay" />
      <meta property="og:title" content="Terms & Conditionsm | Decpay" />
      <meta property="og:image" content="/images/banner.svg" />
    </Helmet>
    <div className="terms-and-conditions">
      <h2>Terms & Conditions</h2>
      <p>Updated on May 8, 2024</p>
      <div className="content">
        <p>Thank you for entrusting Decentro to meet your needs!</p>
        <p>
          Please read these terms carefully. By indicating your acceptance of
          these Terms electronically and/or registering, accessing or using our
          services and products in any way, you acknowledge and agree to be
          bound by them.
        </p>
        <p>
          These Terms constitute a binding and enforceable agreement between you
          (“you”, “your” or “Merchant”) registered with us to receive our
          services and us, Decfin Tech Private Limited. (‘Decfin’, ‘us’, ‘our’
          or ‘we’). These terms, along with the Privacy Policy, and any other
          specific agreements or documents executed in respect of the Services
          provided by us (collectively, the “Terms”) will govern your access to
          and use of decpay.in, our APIs, our content, our products and payment
          collection(“Services”). Services also include those offered by our
          partners, third parties, financial institutions, banks and technology
          service providers.
        </p>
        <p>
          If you do not agree with the Terms or any changes thereof, you should
          immediately stop using the Services. We will construe your continued
          use of the Services as your acceptance of the Terms and the changes
          thereof.
        </p>
        <h4>Scope and Extent of the Terms -</h4>
        <ol>
          <li>
            The Terms outline the terms and conditions that apply to your use of
            the Services. In the event of any conflict between these terms, the
            Privacy Policy and such other documents, the decision on what will
            prevail will be solely ours. Such a decision shall be final and
            binding on you.
          </li>
          <li>
            It is your responsibility to read and acquaint yourself with the
            Terms and our Partners’ terms that apply to your use of the
            Services. The act of registering, accessing and/or using the
            Services signifies your acceptance of the Terms. If you are
            accepting the Terms on behalf of an entity, unless notified to us,
            we will assume that you have requisite authority to do so and
            accordingly, the entity you represent will be bound by the Terms.
          </li>
          <li>
            We reserve the right to modify, pause, decline or terminate any part
            of the Services at any time without notice and assume no liability
            for doing so.
          </li>
          <li>
            We may change or modify these Terms at our sole discretion. Such
            modifications will come into effect promptly upon being posted on
            the website. While we will deploy our best efforts to communicate
            such modifications to your registered email ID, we encourage you to
            periodically review these Terms to better acquaint yourself with the
            revisions we make.
          </li>
        </ol>
        <h4>Definitions -</h4>
        <p>In these Terms,</p>
        <p>
          <strong>“Acquiring Institution”</strong> shall mean various banks and
          financial institutions licensed under the Payment and Settlement
          Systems Act, 2007 to acquire, authorize and authenticate the online
          payment Transactions. This shall also include Card Payment Networks
          authorised under the Act to operate Payment Systems.
        </p>
        <p>
          <strong>“Applicable Law”</strong> means any and all laws, ordinances,
          constitutions, regulations, statutes, treaties, rules, codes, notices,
          licenses, certificates, franchises, permits, principles of common law,
          requirements and orders adopted, enacted, implemented, promulgated,
          issued, entered or deemed applicable by or under the authority of any
          governmental authority or quasi-judicial authority having jurisdiction
          over the Services.
        </p>
        <p>
          <strong>"Authentication"</strong> is the process by which the Facility
          Providers verify whether the Payment Instrument used for the
          Transaction is validated.
        </p>
        <p>
          <strong>"Authorisation"</strong> is the process by which the Issuer
          confirms the sufficiency of balance or limit in respect of a Payment
          Instrument and consequently approves the payment of the Transaction
          Amount.
        </p>
        <p>
          <strong>“Force Majeure Event”</strong> means an event that (a) is not
          reasonably anticipated as of the date hereof, (b) is not within our
          reasonable control of the Party affected by the event, (c) is not the
          result of such Party's negligence or failure to act, and (d) could not
          be overcome by the affected Party's use of due diligence in the
          circumstances. Force Majeure includes, but is not restricted to,
          events of the following types (but only to the extent that such an
          event, in consideration of the circumstances, satisfies the tests set
          forth in the preceding sentence): acts of God; civil disturbance;
          sabotage; strikes; lock-outs; work stoppages; action or restraint by
          court order or public or government authority;
        </p>
        <p>
          <strong>"Facility Providers"</strong> includes banks, financial
          institutions, and technology service providers, including Acquiring
          Institution, Issuing Institution and Card Payment Networks,
          facilitating the Transaction or any part thereof.
        </p>
        <p>
          <strong>“Governmental Authority”</strong> shall mean any national
          governmental authority, statutory authority, regulatory authority,
          government department, agency, commission, board, rule or regulation
          making entity or authority having or purporting to have jurisdiction
          over any Party, or other subdivision thereof to the extent that the
          rules, regulations, standards, requirements, procedures, or orders of
          such authority, body, or organisation have the force of any Applicable
          Law or any court or tribunal having jurisdiction, including but not
          limited to the Reserve Bank of India.
        </p>
        <p>
          <strong>“Intellectual Property”</strong> means all patents,
          trademarks, permits, service marks, brands, trade names, trade
          secrets, proprietary information and knowledge, technology, computer
          programs, databases, copyrights, licenses, franchises, formulae,
          designs and other Confidential Information.
        </p>
        <p>
          <strong>“Issuing Institution”</strong> in respect of a Customer, means
          the bank or institution which has issued the valid card and/or any
          other Payment Instrument to the Customer with which Customer makes the
          payment for the Products / Services offered by the Merchant.
        </p>
        <p>
          <strong>"Payment Instrument"</strong> includes credit card, debit
          card, bank account, Prepaid Payment Instrument or any other instrument
          issued under Applicable Law, that enables the Customer to initiate and
          complete an online Transaction by paying the Transaction Amount for
          purchasing Products or availing Services from the Merchant.
        </p>
        <p>
          <strong>“Payment Mechanism”</strong> shall mean the process by which
          the Service Provider facilitates payments.
        </p>
        <p>
          <strong>"Payment System"</strong> has the meaning ascribed to it in
          the Act.
        </p>
        <p>
          <strong>"Partner Services"</strong> means the services/products owned
          and provided to you by our Partner(s). Partner Services may form part
          of the Services or may be made available to you separately.
        </p>
        <p>
          <strong>"Refund"</strong> means the process by which the Transaction
          Amount is returned on the Merchant’s request.
        </p>
        <p>
          <strong>"Restricted Business"</strong> means any activity that is
          illegal and unlawful and also includes any activity that we may notify
          as being restricted.
        </p>
        <p>
          <strong>"Transaction"</strong> means an order placed by the Customer
          with the Merchant by paying the Transaction Amount to the Merchant for
          the purpose of purchasing Products or availing Services from the
          Merchant.
        </p>
        <p>
          <strong>“Transaction Amount”</strong> shall mean the amount paid by
          the Customer to the Merchant pursuant to a Transaction.
        </p>
        <p>
          <strong>“Wallet”</strong> refers to a payment account or virtual
          account generated and provided to you as part of the Services as per
          the Terms or any other agreement you may sign with us.
        </p>
        <h4>Eligibility to use our Services -</h4>
        <p>You shall register, access and use our Services only if-</p>
        <ol>
          <li>
            you are duly incorporated or established under Applicable Laws and
            have full capacity and authority to own and operate your business.
          </li>
          <li>
            you have full capacity, authority and all necessary licences,
            permits and consents to enter into and to exercise the rights under
            the Terms, perform the obligations under the Terms and have the
            authority and eligibility to access and use the Services.
          </li>
          <li>
            the execution, delivery and performance of these Terms has been duly
            authorized by way of necessary corporate actions.
          </li>
          <li>
            the information provided by you in respect of (a), (b) and (c) above
            and in relation to the access and use of the Services is true,
            accurate and complete.
          </li>
        </ol>
        <h4>Services We Offer –</h4>
        <p>Our Services include –</p>
        <ol>
          <li>
            Our Services include –
            <ol>
              <li>One-time Payment Collections via UPI / IMPS /</li>
              <li>Recurring Payment Collections via UPI / e-NACH</li>
            </ol>
          </li>
          <li>
            When you register, access and use our Services,
            <ol>
              <li>
                you register solely for the Services that are described in the
                agreement or statement of work specific to India. Any
                modification or addition to the Services you originally sign up
                for shall be in writing and will in most cases, attract
                additional charges.
              </li>
              <li>
                you authorise us and our Partners to carry out requisite actions
                on your behalf. These include holding, receiving, transferring
                and settling funds on your behalf through Transactions.
                Additionally, you also grant us a license to use your
                information in respect of the Services.
              </li>
              <li>
                you understand that we merely facilitate Transactions and assume
                no liability in respect of them. We do not verify their
                authenticity and assume that you have authorised them unless you
                provide us with sufficient notice that a Transaction is invalid,
                incorrect or suspect.
              </li>
              <li>
                you agree that we do not provide any advice, nor do we act as
                your agent in any manner. We merely provide Services on an as is
                and as available basis.
              </li>
              <li>
                you will receive a limited license that is not exclusive or
                transferrable to use the Services solely for the purposes that
                are authorised by us. This does not however in any manner
                transfer any title or ownership over the Services from us to
                you.
              </li>
              <li>
                you agree that we do not take any responsibility or liability
                for the Services provided or facilitated by our Partners. You
                agree to become aware of the terms under which the Services are
                offered by our Partners and undertake to receive them based on
                your assessment of the terms and at your own risk.
              </li>
              <li>
                you understand that all the Services provided by our Partners
                will be facilitated by us and you may not independently enter
                into any understanding or agreement with our Partners in respect
                of the Services contemplated herein or already forming part of
                any agreement with us.
              </li>
              <li>
                you agree to use the Services only for lawful purposes and you
                agree not to authorise any Transactions that relate to any
                Restricted Businesses/Goods.
              </li>
              <li>
                you agree to pay requisite fee/service charges that are mutually
                agreed between us as consideration for the Services.
              </li>
              <li>
                You understand and authorise us to deduct amounts payable to us
                including Fees and taxes from funds held by us on your behalf.
                Such authorisation shall survive the termination of any
                agreement with us and remain in effect until all the sums owed
                tp us are paid in full by you.
              </li>
              <li>
                you understand that we may impose additional obligations on you
                from time to time but will notify you in advance of the same.
              </li>
              <li>
                you will ensure that all the hardware and software you use is
                compatible with the Services.
              </li>
            </ol>
          </li>
          <li>
            You further agree that our provision of Services and your use
            thereof is subject to –
            <ol>
              <li>submission of KYC Documents to our satisfaction;</li>
              <li>
                adequate verification of the information and documentation you
                provide as detailed in clause [.] from time to time;
              </li>
              <li>
                completion of background checks/due-diligence on you to our
                satisfaction;
              </li>
              <li>
                our assessment of your creditworthiness, if required, from time
                to time.
              </li>
            </ol>
          </li>
          <li>
            We reserve the right to modify, pause, decline or terminate any part
            of the Services for any reason that warrants such action. We will
            provide you with notice where ever feasible, but you agree that we
            may do so without notice and assume no liability in such an action
            and event.
          </li>
        </ol>
        <h4>On-boarding</h4>
        <ol>
          <li>
            You may be required to register with us before availing our
            Services. During this process, you will be requested to provide
            information and requisite documents including details of the entity
            you represent, authorisation letters, bank details, etc., to enable
            us to (a) perform necessary checks to ensure you comply with our
            onboarding criteria; (b) verify your credentials by performing
            due-diligence in certain cases. We will provide the Services only
            when we are satisfied that the information and documentation
            provided by you are true, accurate and meet our requirements. Should
            these checks/verification fail, we reserve the right to decline the
            Services to you.{" "}
          </li>
          <li>
            You agree to notify us of any changes to the information or
            documentation provided to us promptly upon becoming aware of such
            changes and in any event, no later than 24 hours of becoming aware
            of such changes. Any delay or failure to do so may result in
            disruption or termination of the Services. If these changes are
            significant and do not meet our customer criteria , we reserve the
            right to terminate the Services
          </li>
          <li>
            You may also be required to provide us with additional information
            or documentation during the time you receive the Services, including
            those required by our Partners within 48 hours of such request.
          </li>
          <li>
            You consent to –
            <ol>
              <li>
                providing us with information that could include personal data;
              </li>
              <li>
                us obtaining credit information report wherever necessary;
              </li>
              <li>us conducting background checks on you, if required.</li>
              <li>
                us providing your information or documents to third parties, if
                required.{" "}
              </li>
              <li>
                us modifying your activity, the Transactions and any other
                details associated with the Services we provide to you.
              </li>
            </ol>
          </li>
        </ol>
        <h4>Fees</h4>
        <ol>
          <li>
            Your access to and use of the Services is subject to you paying us
            the fees (“Fees”) as applicable. These Fees may be in the nature of
            (a) subscription fees; (b) recurring fees; (c) additional fees
            payable in certain cases. You will be notified of the Fees
            applicable to the Services availed by you at the time of onboarding
            and we will also inform you of the manner in which the Fees are
            payable to us.{" "}
          </li>
          <li>
            You agree to assume full responsibility for the taxes payable in
            respect of the Services and the Transactions. You understand that
            this includes assessing the taxes payable, deducting them,
            collecting them and remitting them to relevant authorities.
          </li>
          <li>
            You understand and agree that these Fees are exclusives of taxes and
            other charges and it is your responsibility to remit taxes as
            applicable to relevant authorities.
          </li>
        </ol>
        <h4>Account Activity</h4>
        <ol>
          <li>
            You may appoint an authorised user (“Authorised User”) to login to
            your Account and take actions on your behalf. You will promptly
            provide us with details of the Authorised User so appointed. You
            will ensure that the Authorised User is fully aware of the Terms and
            will comply with them. You will be responsible and liable for any
            and all actions taken by the Authorised User. References to you in
            these terms will include the Authorised User.{" "}
          </li>
          <li>
            You agree to regularly login to your account to keep yourself
            updated of (a) any obligations that may arise further to what is
            detailed in the Terms; (b) any notices we/our Partner may have sent
            to you in respect of the Services/Transactions; (c) any changes to
            the Terms.
          </li>
          <li>
            You agree to be responsible for and take all necessary steps to (a)
            keep your account credentials safe and confidential; (b) adapt
            reasonable security standards and at the very least, those required
            by law to safely access and use the Services; (c) ensure that your
            usage of the Services doesn’t threaten its integrity; (d) inform us
            of any purported or actual threat to the Services including those in
            the form of security or data breach; (e) notify us of any
            unauthorised access.
          </li>
          <li>
            If you have not used our Services for more than six months, we
            reserve the right to deem your account as inactive and accordingly
            suspend or terminate the Services. This does not however absolve you
            of any payment obligations or other terms that survive the
            termination of our agreement with you.
          </li>
          <li>
            In the event you also have funds that are dormant in your account,
            to the extent permitted by law, we reserve the right to abandon them
            after attempting to contact you about them on your registered
            address thrice.
          </li>
        </ol>
        <h4>Communication</h4>
        <ol>
          <li>
            By providing your contact details and availing our Services, you
            agree that we may contact you regarding the Services and any
            follow-ups thereof, any new products/offers/promotions/marketing
            campaigns that we believe will be useful to you, any information
            through any means including SMS, phone, email and Whatsapp, etc. You
            agree to receive such communications from us/our partners/ third
            party service providers.{" "}
          </li>
          <li>
            For the purposes above, you expressly waive any registration or
            preference made under DND/NCPR list under the applicable Telecom
            Regulatory Authority of India (TRAI) regulations.
          </li>
          <li>
            If you opt out of receiving such communication any further, such
            option shall be applicable prospectively and shall not be applicable
            to communication already received/data already shared with our
            partners, third parties, financial institutions, banks and
            technology service providers.
          </li>
        </ol>
        <h4>KYC Documents and Personal Information</h4>
        <ol>
          <li>
            You understand that we may require documents from you that contain
            personal information (“KYC Documents”) without limitation, for the
            following purposes -
            <ol>
              <li>to be able to offer our Services;</li>
              <li>to verify and authenticate your identity;</li>
              <li>to provide assistance and support;</li>
              <li>to perform due diligence on you;</li>
              <li>to prevent risk and fraud;</li>
              <li>
                to undertake anti-money laundering and countering the finance of
                terrorism measures;
              </li>
              <li>
                to communicate with you and provide you with updates and alerts,
                information regarding your transactions, share marketing
                campaigns, etc.
              </li>
              <li>
                to provide information to government authorities, the
                government, law enforcement authorities, regulatory authorities
                and judicial authorities when requisitioned or otherwise
                required.
              </li>
              <li>to report suspicious activity;</li>
            </ol>
          </li>
          <li>
            You agree to provide the KYC Documents at the time of Onboarding and
            as and when requested by us and warrant that such documents and
            information are true, accurate and complete. You further agree to
            keep them updated and intimate us immediately, and in any case not
            more than two days of becoming aware of such changes.
          </li>
          <li>
            You understand that we may require you to provided further
            information from you if so demanded under applicable law or by
            governmental authorities and our partners, third parties, financial
            institutions, banks and technology service providers. You failure to
            provide such additional information may result in the suspension of
            Services until such information is provided.
          </li>
          <li>
            You understand that we may verify or derive your Personal
            Information through publicly available information on government
            portals or through credit bureaus and consent to such verification.
          </li>
          <li>
            You understand that we may review, retain and/or disclose any
            information in relation to the Service(s) as necessary as required
            under any Applicable Laws, legal process or governmental request.
          </li>
        </ol>
        <h4>Your Representations and Warranties</h4>
        <ol>
          <li>
            You represent and warrant to us that –
            <ol>
              <li>
                you have full capacity, authority and all necessary licences,
                permits and consents to enter into and to exercise the rights
                under the Terms, perform the obligations under the Terms and
                have the authority and eligibility to access and use the
                Services.
              </li>
              <li>
                the Transactions undertaken using the Services are legitimate
                and do not pertain to any Restricted Business/Goods.
              </li>
              <li>
                you will comply with and continue to be compliant with all laws,
                ordinances, constitutions, regulations, statutes, treaties,
                rules, codes, licences, certificates and orders applicable to
                your use of the Services.
              </li>
              <li>
                your use and access of the Services and your agreement with us
                does not conflict with any other existing obligations.
              </li>
              <li>
                the information and documentation you provide to us will be
                true, complete and accurate in all respects and at all times.
                You will promptly notify us of any changes to the
                information/documentation already provided to us promptly upon
                becoming aware of these changes and in any event, no later than
                24 hours from becoming aware of such changes.
              </li>
              <li>
                you will not use the Services for any illegal or fraudulent
                purposes.
              </li>
              <li>
                you have adequate resources to ensure that your use of the
                Services does not compromise or threaten the integrity of the
                Services in any manner.
              </li>
              <li>
                you will not disrupt or cause any interruption or damage to the
                Services.
              </li>
              <li>
                you will not hold us liable for any delay, loss, costs, damages,
                claims, or actions that you may suffer in respect of any
                downtime, delay or failure of Services that arise due to reasons
                outside of our control.
              </li>
              <li>
                you will not engage in any activity that involves any virtual
                currencies or cryptocurrencies or any instruments of like nature
                that are prohibited.
              </li>
              <li>
                Your customers have consented to you sharing their information
                with us and our affiliates in connection with the Services
                and/or as required under applicable law or by governmental
                authorities as and when demanded.
              </li>
            </ol>
          </li>
        </ol>
        <h4>Your Covenants and Obligations</h4>
        <ol>
          <li>
            You agree to use the Services only in accordance with law and all
            Transactions authorised or carried out by you using the Services
            comply with law, do not pertain to Restricted Business/Goods, are
            not illegal or fraudulent. Further, you agree to use the Services
            only for the purposes declared by you at the time of onboarding.
          </li>
          <li>
            You will provide necessary information and documentation as and when
            requested by us, no later than 48 hours from such request.
          </li>
          <li>
            You authorise us to carry out all necessary actions in respect of a
            Transaction including holding, receiving, transferring and settling
            funds on your behalf.
          </li>
          <li>
            You will not attempt to create any services that mimic the Services
            or are similar to the Services using the Services directly or
            indirectly.
          </li>
          <li>
            You will not reverse engineer, compile, modify, translate or
            disassemble any software belonging to us directly or indirectly.
          </li>
          <li>
            You will cooperate with any inquiries we may undertake for verifying
            the use and access of the Services including any threatened or
            actual breach of the Terms.
          </li>
          <li>
            You are responsible for all Transactions carried out through our
            Services by you or your customers and will be liable for all actions
            arising therefrom.
          </li>
          <li>
            You are responsible for obtaining your end-customer’s consent
            wherever required, including without limitation for sharing their
            information and other details related to them with us.
          </li>
          <li>
            You are solely responsible for any delivery, support services,
            collection, and/or any other obligations or services relating to
            your products or services. You shall indemnify us against any claim
            arising from such services or obligations and shall bear any and all
            expenses and/or costs relating thereto.
          </li>
          <li>
            You declare that you or your affiliates and/ or its Beneficial Owner
            are not a Politically Exposed Person. Should this declaration become
            untrue during any period of your use of our Services, you shall
            immediately inform us of such change in writing. The terms
            Beneficial Owner and Politically Exposed Person shall have the
            meaning ascribed to it in the RBI Master Direction (MD) on KYC.
          </li>
          <li>
            You agree to undertake all the steps required to integrate our
            Services onto your platform as required by us.
          </li>
          <li>
            You agree that we shall be liable only for those actions that are
            directly attributable to us.
          </li>
          <li>
            You agree to retain all data relating to the transaction for such
            period prescribed by Applicable Laws and where not specified, for a
            period of 5 years from the date of the transaction. You agree to
            make all such data available as and when required by us.
          </li>
          <li>
            You shall not represent yourself as our agent/representative under
            any circumstances.
          </li>
          <li>
            You agree that we have the right to set off any amounts due to us
            against any sums owing to you.
          </li>
          <li>
            You shall not assign these Terms or any rights and obligations
            hereinunder or under any Agreement with us to any third party. We
            shall have the right to assign the rights and obligations under
            these Terms.
          </li>
        </ol>
        <h4>Security and Privacy</h4>
        <p>
          All the data and information you provide to us or authorise us to
          collect will be governed under our Privacy Policy.
        </p>
        <h4>Indemnity</h4>
        <p>
          You agree to defend, indemnify and hold us, our Partners and relevant
          third party service providers against and in respect of any and all
          losses arising out of (a) breach of the Terms; (b) breach of
          applicable laws; (c) your negligence or wilful misconduct in the
          performance of your obligations under the Terms; and (d) any
          inquiries, investigation or actions by relevant authorities.
        </p>
        <br />
        <h4>Disclaimers</h4>
        <p>
          WE PROVIDE ANY AND ALL SERVICES ON AN “AS-IS” BASIS AND MAKES NO
          REPRESENTATIONS OR WARRANTIES AS TO THE SERVICES PROVIDED. WE DISCLAIM
          ALL IMPLIED WARRANTIES, INCLUDING ALL IMPLIED WARRANTIES OF
          MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, IN CONNECTION
          WITH THE TERMS.
        </p>
        <br />
        <h4>Third-Party Links</h4>
        <p>
          During your use of the Services, you may encounter third-party links.
          These may be in the nature of links to other websites/products. We do
          not assume any responsibility for the content or functionality of such
          links and hence, will not be liable for any damage or loss caused or
          alleged to be caused by or in connection with use of or reliance on
          any such content, goods or services available on or through any such
          links.
        </p>
        <h4>Restricted Businesses/Goods</h4>
        <p>You are prohibited from using our Services -</p>
        <ol>
          <li>
            in any manner that breaches applicable laws or causes us to breach
            applicable laws;
          </li>
          <li>
            for unlawful or fraudulent purposes or in an unlawful or fraudulent
            manner;
          </li>
          <li>
            in a manner that can potentially cause harm or abuse or incite
            violence;
          </li>
          <li>
            to hold yourself out in any manner including making any unauthorised
            advertising or promotions.
          </li>
          <li>
            in a manner that would threaten the integrity of our Services
            including introducing viruses, spyware.
          </li>
        </ol>

        <p>
          You cannot use our Services to perform any of the following activities
          that involve -
        </p>
        <ol>
          <li>cryptocurrencies or virtual currencies;</li>
          <li>
            financial services, including (and not limited to):-
            <ol>
              <li>
                Acceptance of deposits and other repayable funds from the
                public, including crowdfunding;
              </li>
              <li>Lending;</li>
              <li>Buy now pay later services;</li>
              <li>Financial leasing;</li>
              <li>Money or value transfer services;</li>
              <li>Money and currency changing;</li>
              <li>
                Issuing and managing means of payment (e.g. credit and debit
                cards, cheques, traveller's cheques, money orders and bankers'
                drafts, electronic money);
              </li>
              <li>Financial guarantees and commitments;</li>
              <li>
                Carry out a business that provides trading services in:
                <ul>
                  <li>
                    Money market instruments (cheques, bills, certificates of
                    deposit, derivatives etc.);
                  </li>
                </ul>
                <li>Foreign exchange;</li>
                <li>Exchange, interest rate and index instruments;</li>
                <li>Transferable securities;</li>
                <li>Commodity futures trading.</li>
              </li>
            </ol>
          </li>
          <li>
            Participation in securities issuance and the provision of financial
            services related to such issues;
          </li>
          <li>Individual and/or collective portfolio management;</li>
          <li>
            Safekeeping and administration of cash or liquid securities on
            behalf of other persons;
          </li>
          <li>
            Otherwise investing, administering or managing funds or money on
            behalf of any other persons;
          </li>
          <li>
            Underwriting and placement of life insurance and other investment
            related insurance.
          </li>
          <li>Matchmaking or dating services;</li>
          <li>Interactive video platforms and chatting services;</li>
          <li>Marketplaces;</li>
          <li>Facilitation, sale or distribution of chemicals;</li>
          <li>Pharmaceuticals;</li>
          <li>Oil and gas companies, as well as related activities;</li>
          <li>Bidding fee auctions;</li>
          <li>Political organizations;</li>
          <li>Religious organizations;</li>
          <li>Surveillance equipment, for example, spy cameras;</li>
          <li>Alcohol, tobacco and e-cigarette products;</li>
          <li>Marijuana, CBD Oil, and drug-related paraphernalia;</li>
          <li>
            Import or export of specified ‘dual use goods’ listed on the defence
            and strategic goods list;
          </li>
          <li>
            Regulated medical devices and services, including fetal gender
            diagnosis;
          </li>
          <li>Travel-Related Arrangement Services; or</li>
        </ol>
        <p>
          other high risk activities in accordance with our internal policies,
          our banking partners’ policies or the policies of participants in our
          payment network.
        </p>
        <h4>Our Services will not be provided to -</h4>
        <ol>
          <li>
            adult entertainment and related services, including pornography,
            escort services, prostitution services and/or any form of
            advertising of or for sexual services;
          </li>
          <li>
            gambling services where it is illegal or offering gambling services
            without the required permits or licenses;{" "}
          </li>
          <li>
            counterfeit or unauthorised goods, or products or services; such as
            goods or services which infringe upon intellectual property or
            proprietary rights of third parties;
          </li>
          <li>arms and weapons manufacturing, sales and supplies;</li>
          <li>
            the offering of regulated financial services (directly or
            indirectly) without a valid license and/or registration;
          </li>
          <li>
            The sale and/or distribution of any product or service without the
            requisite permit or licence in their place of operation
            (governmental or otherwise);
          </li>
          <li>
            The sale and/or distribution of any products or services which
            promote or encourage discrimination, including discrimination based
            on race, gender, religion, nationality, disability, sexual
            orientation or age;
          </li>
          <li>Ponzi/ Pyramid Scheme/Multi-Level Marketing/ Telemarketing;</li>
          <li>
            The trade of restricted and/or endangered animal species and
            products derived from them;
          </li>
          <li>
            The sale, distribution and/or trade of archaeological and cultural
            relics, such as the unlicensed export and trade of Iraqi Cultural
            Property;
          </li>
          <li>
            Any transactions made for no genuine commercial purpose, such as the
            pooling of funds between multiple parties or the cashing out of
            credit or stored value cards;
          </li>
          <li>
            Any other businesses or transactions outside of our risk appetite
            based on our internal policies, our banking partners’ policies or
            the policies of participants in our payment network.
          </li>
        </ol>
        <h4>Confidentiality</h4>
        <p>
          You understand and acknowledge that any oral or written information
          exchanged between us in connection with the Services is confidential
          in nature. You and us/our Partners and third party service providers
          shall maintain confidentiality of all such confidential information,
          and without obtaining the written shall not disclose any relevant
          confidential information to any third parties, except for the
          information that: (a) is or will be in the public domain (other than
          through the receiving Party’s unauthorized disclosure); (b) is under
          the obligation to be disclosed pursuant to the applicable laws or
          regulations, rules of any stock exchange, or orders of the court or
          other government authorities; or (c) is required to be disclosed by
          any Party to its shareholders, investors, legal counsels or financial
          advisors regarding the transaction contemplated hereunder, provided
          that such shareholders, investors, legal counsels or financial
          advisors shall be bound by the confidentiality obligations similar to
          those set forth in this Section.
        </p>
        <p>
          You agree and authorize us to share your information/details with our
          partners, third parties, financial institutions, banks and technology
          service providers to the extent required to provide the product and
          Services including value-added services associated with such
          products/Services.
        </p>
        <p>
          Confidentiality obligations shall survive the termination of this
          Agreement.
        </p>
        <h4>Intellectual Property</h4>
        <p>
          We, along with our licensors as the case may be, own all the
          Intellectual Property in relation to the Services we provide. These
          also include any and all content and material we provide as part of
          the Services.
        </p>

        <p>
          You will receive a limited license that is (a) not exclusive (b)
          revocable and (c) not transferrable to use the Services solely for the
          purposes that are authorised by us as per the Terms. You will not
          reverse engineer, compile, modify, translate or disassemble any
          software belonging to us directly or indirectly and will not, in any
          manner transmit the Services. Further, you agree not to alter or
          remove any notices forming part of the Services including those
          contained in the software. You also agree not to use logos, trade
          names/marks, service marks, domain names, and other
          attributes/features that are associated with us or the Services.
        </p>

        <p>
          You also agree that the Services may contain Confidential Information,
          whether or not designated as confidential and such information shall
          not be disclosed by you to anyone without our prior written consent.
        </p>
        <h4>Term and Termination </h4>
        <ol>
          <li>
            These Terms shall govern your access to and use of the Services from
            the date of your registration until terminated by you or us in
            accordance with the terms contained herein.
          </li>
          <li>
            We/you may terminate the Services by giving a prior notice of at
            least 30 days. We reserve the right to terminate the Services in our
            sole discretion immediately upon – (a) your breach of the Terms; (b)
            any illegal use of the Services; (c) use of the Services for uses
            pertaining to Restricted Businesses/Goods; (d) substantial change in
            the information or documentation you provide to us; (e) any change
            to your credit worthiness; (f) us having any reason to believe your
            access to and use of Services will cause any harm or threaten the
            integrity of the Services; (g) any unauthorised access to your
            account; (h) you are subject to any voluntary or involuntary
            proceeding in bankruptcy, liquidation, dissolution, receivership or
            similar action for the benefit of creditors.
          </li>
          <li>
            Termination does not absolve you of payment and confidentiality
            obligations. Upon termination you agree to –
            <ol>
              <li>pay all the Fees due until the date of such termination;</li>
              <li>
                execute all the Transactions and not authorise, initiate or
                undertake any new Transactions;
              </li>
              <li>stop using the Services.</li>
            </ol>
          </li>
        </ol>
        <h4>Limitation of Liability </h4>
        <ol>
          <li>
            You acknowledge and agree that to the extent permitted by applicable
            law, we/our Partners/third party service providers shall not be
            liable under the Terms for lost revenues, loss of goodwill, service
            interruption, computer damage or system failure or the cost of
            substitute services, or indirect, special, incidental,
            consequential, exemplary, or punitive damages, losses or expenses
            resulting from your access to and use of the Services.
          </li>
          <li>
            Notwithstanding anything contained in the Terms or any applicable
            law, our/our Partners’/our third party service providers’ aggregate
            liability under the Terms shall not exceed the Fees paid/payable by
            you in the preceding three months from the date on which such
            liability arises.
          </li>
        </ol>
        <h4>Force Majeure</h4>
        <p>
          Notwithstanding anything contained in the Terms, you, us, our Partners
          or third party service providers shall not be liable for any failure
          to perform an obligation under the Terms if performance is prevented
          or delayed by a Force Majeure event.
        </p>
        <h4>Governing law</h4>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of India. Any disputes arising out of or in connection with with
          the Terms shall be exclusively referred to the Courts in Bangalore.
        </p>
        <h4>SPECIFIC TERMS OF USE </h4>
        <ol>
          <li>
            In the event where we are responsible for delivery of goods/
            services, the payment to you shall not be effected later than on
            Ts+1, where Ts is the time of the Transaction.
          </li>
          <li>
            In cases where you is responsible for delivery, the payment to you
            shall not be effected later than on Td+1 basis, where Td is the time
            of delivery.
          </li>
          <li>
            In cases where the agreement with you provides for retaining the
            amount by us till expiry of refund period, payment to you shall not
            be effected later than on Tr+1 basis where Tr is the expiry time of
            refund period.
          </li>
          <li>
            Refund transactions will be routed back through the Escrow Account.
          </li>
          <li>
            We shall settle the Transaction Amount (minus Service Provider Fees
            and chargeback Amount and penalties) into your account within two
            (2) Bank Working Days following the date of the Transaction.
          </li>
          <li>
            We shall in our sole discretion prescribe limits on the Transaction
            Amount.
          </li>
          <li>
            You agree that we shall refund the amount back to the source in the
            event it is not feasible or suitable to settle the funds held in our
            Escrow Account.
          </li>
        </ol>
        <h4>Chargebacks</h4>
        <p>
          If Acquiring Institution or the Issuing Institution communicates the
          receipt of a Chargeback from a Customer to us, then we will notify you
          of the same.
        </p>
        <p>
          You shall furnish to us documents and information{" "}
          <strong>("CHARGEBACK DOCUMENTS")</strong> pertaining to the
          Transaction associated with the Chargeback in order to substantiate
          (I) the completion of the aforesaid Transaction and/or; (II) delivery
          of goods / services sought by the Customer pursuant to the said
          Transaction.
        </p>
        <p>
          You agree and acknowledges that (I) if you are unable to furnish
          Chargeback Documents; (II) the Issuing Institution is not satisfied
          with the Chargeback Documents furnished by you, then the Issuing
          Institution shall be entitled to order the Acquiring Institution or us
          to effect a reversal of the debit of the Chargeback Amount associated
          with the Chargeback such that the said Chargeback Amount is credited
          to the Customer’s Payment Instrument.
        </p>
        <p>
          If the Acquiring Institution or the Issuing Institution charges the
          Chargeback Amount to us, then you agree and acknowledge that the we
          are entitled to charge such Chargeback Amount to the you by way of
          deduction from the Transaction Amounts to be settled to you.
        </p>
        <p>
          You further agree and acknowledge that following us incurring the
          charge stipulated in this clause, the available Transaction Amounts
          are insufficient for deduction of the Chargeback Amount, then we are
          entitled to issue a debit note seeking reimbursement of the Chargeback
          Amount you shall reimburse the Chargeback Amount within thirty (30)
          days of receipt of the debit note.
        </p>
        <p>
          On the issuance of notice of termination, we reserve the right to
          withhold from each settlement made during the Notice Period, a sum
          computed based on a Stipulated Percentage (defined hereinbelow) for a
          period of one hundred and twenty (120) days{" "}
          <strong>("WITHHOLDING TERM")</strong> from the date of termination of
          this Agreement. The sums so withheld shall be utilized towards
          settlement of Chargebacks. After processing such Chargebacks, we shall
          transfer the unutilized amounts, if any, to you upon completion of the
          Withholding Term. The <strong>‘STIPULATED PERCENTAGE’</strong>
          is the proportion of the Chargeback Amounts out of the total
          Transaction Amounts settled during the subsistence of this Agreement.
        </p>
        <p>
          Notwithstanding anything contained herein, if the amount withheld
          pursuant is insufficient to settle chargeback amounts received during
          the Withholding Term, then we are entitled to issue a debit note
          seeking reimbursement of the Chargeback Amount. You shall reimburse
          the Chargeback Amount within thirty (30) days of receipt of the debit
          note.
        </p>
        <h4>Refunds</h4>
        <p>
          You agree and acknowledge that subject to availability of funds
          received in the Escrow Account pursuant to Transactions, you are
          entitled to effect Refunds.
        </p>
        <p>
          You further agree and acknowledge that initiation of Refunds is at
          your discretion and we shall process a Refund only upon such
          initiation.
        </p>
        <p>
          All Refunds initiated by you shall be routed through the same
          Acquiring Institution that processed the Transaction Amount related to
          the Refund.
        </p>
        <h4>Miscellaneous</h4>
        <p>
          You represent and warrant that (i) You shall during the entire term of
          the usage of the Services, implement, observe and comply with
          applicable requirements prescribed under Applicable Law including but
          not limited to the provisions of the Payment Aggregator Guidelines.
          You shall further ensure that Your operations are in compliance with
          the Payment Aggregator Guidelines and You shall not undertake any
          action in breach of the same (ii) You shall on Your website/web
          app/mobile site/mobile app clearly indicate/display (a) the return and
          refund policy of Your products/ services to Your customers, including
          the timelines for processing such returns, refunds or cancellations;
          and (b) the general terms of use and conditions of use by Your
          customers. You shall ensure that You deliver products and services in
          accordance with instructions of the customers. (iii) You shall at no
          time hold, store, copy or keep any customer data relating to a
          customer's Payment Instrument and shall notify in writing to us
          without any delay if You suspect or have become aware of a possible
          security breach related to any customer data. (iv) You shall not store
          any data pertaining to the Payment Instrument / customer Payment
          Instrument credentials. On demand, You shall provide a written
          confirmation, in a form and manner acceptable to us and Facility
          Providers, certifying compliance to this aspect.
        </p>
        <p>
          You shall set up a comprehensive customer grievance redressal
          mechanism which provides the procedure for addressing complaints
          received from Your customer and You shall include the details of the
          person designated by You for handling such customer complaints. It is
          clarified that such customer grievance redressal mechanism shall
          provide the facility to the customers for registering their complaints
          over phone, email, or any other electronic means. You shall respond to
          such grievances or complaints received from Your customers within a
          period of 5 (five) business days from the date of receiving such
          grievance or complaint. You shall be solely responsible for sorting or
          handling of any complaints received against You.
        </p>
        <p>
          You shall comply with or enter into an agreement with a third party
          service provider of payment processing services for compliance with
          the PCI DSS, as may be amended from time to time and the Payment
          Application-Data Security Standard (“ PA-DSS ”), if applicable. You
          shall also submit an annual report in writing to us, signifying proof
          of compliance with the above.
        </p>
        <p>
          If You become aware that You will not be or are likely not to be, in
          compliance with PCI DSS or PA DSS for any reason, You will promptly
          report in writing to us such non- compliance or likely non-compliance.
        </p>
        <p>
          You shall provide us with evidence of compliances listed in this
          Clause at our request and provide, or make available, to us copies of
          any audit, scanning results or related documents relating to such
          compliance. Notwithstanding the above, we shall have the right to
          conduct a security audit to check Your compliance with this Clause 6
          and in such cases, You shall extend full co-operation to us and its
          representatives so as to enable them to conduct the audit to their
          sole satisfaction.
        </p>
        <p>
          You agree to adopt and enforce any information security requirement
          that we may deem advisable in order to facilitate reasonable security
          processes and procedures.
        </p>
      </div>
    </div>
  </React.Fragment>
);

export default TermsAndConditions;
